import * as React from 'react';
import Layout from '../component/layout'
import { Link }  from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image'
import ReactMarkdown from 'react-markdown'
import fetchGSheet2 from '../component/fetchGSheet'
import _ from 'lodash';


const MentalHealth = () => {
    const [entries, setEntries] = React.useState(undefined)
    const [affirmingMentalHealthResources, setAffirmingMentalHealthResources] = React.useState([])
    const [dependsOnCounsellor, setDependsOnCounsellor] = React.useState([])
    React.useEffect(() => {
        fetchGSheet2('Mental Health Resources', setEntries)
        if (entries === undefined) return;

        let groupedEntries = _.mapValues(_.groupBy(entries, 'type'), list => list.map(resource => _.omit(resource, 'type')))
        setAffirmingMentalHealthResources(groupedEntries['LGBTQIA+ Affirming Mental Health Resources'])
        setDependsOnCounsellor(groupedEntries['Affirming Practices cont. Depends on Counsellor'])
    }, [entries])

    const CUSTOM_COMPONENT_RENDER = {
        a: ({node, ...props}) => <a className='font-sylexiad' {...props} target='_blank' rel='noopener noreferrer'/>,
        p: ({node, ...props}) => <p className='font-sylexiad text-2xl' {...props} />,
        ul: ({node, ...props}) => <p className='statement-list text-xl' {...props} />
    }

    return (
        <Layout title={'Mental Health | YouYou'}
                keywords={['mental health', 'queerness', 'mental help', 'singapore']}>
            <div className='grid grid-rows-auto grid-cols-1 md:grid-cols-2 gap-4 justify-center items-stretch m-3 md:m-10'>
                {/* row */}
                <div className='col-span-1 md:col-span-2 font-sylexiad'>
                    <h1 className='title'>Mental Health Resources in Singapore</h1>
                    <p className='subtitle text-2xl whitespace-pre-line'>Other Resources
                        : <Link to='/christianity-and-queerness' className='underline'>{'Christianity & Queerness'}</Link> | <Link to='/islam-and-queerness' className='underline'>{'Islam & Queerness'}</Link> | <Link to='/queer-resources' className='underline'>{'Queer Resources'}</Link>
                    </p>
                    <br />
                    <p className='text text-base'>Please <a href='mailto:theskinnyhobbit@gmail.com?subject=LGBTQ%20Affirming%20Mental%20Health%20Resource' className='underline'>contact our friendly webmaster</a> if you have a resource to add to the list! :)</p>
                    <p className='text text-base'>Please note that the below links are provided for information only, and are not endorsements.</p>
                </div>

                {/* row */}
                <div className='col-span-1'>
                    <StaticImage src='../images/YouYou_Breathe_You_Will_Be_Ok.webp'
                                alt='Breathe. You will be ok.'
                                className='rounded-md shadow-lg' />
                </div>
                <div className='col-span-1 justify-center align-center text-center flex'>
                    <div className='my-auto'>
                        <strong className='title'>In Crisis? 24/7 Hotlines</strong>
                        <div className='text-left mt-2'>
                            <p className='subtitle text-2xl'>IMH Crisis Hotline: 6389 2222</p>
                            <p className='subtitle text-2xl'>Samaritans of Singapore: 1800-221-4444</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='grid grid-rows-auto grid-cols-1 md:grid-cols-3 gap-4 justify-center items-stretch m-3 md:m-10'>
                {/* row */}
                <div className='col-span-1 font-sylexiad'>
                    <h2 className='title'>What is Conversion Therapy?</h2>
                </div>

                <div className='col-span-1 md:col-span-2 font-sylexiad text-2xl'>
                    <p>Conversion therapy is any attempt to change a person’s sexual orientation, gender identity, or gender expression using psychological or spiritual interventions. using psychological or spiritual interventions. It is also known as “Sexual Orientation and Gender Identity Change Efforts” (SOGICE).</p>
                    <br />
                    <p>It may also be referred to using terms like:</p>
                    <ol className='statement-list pl-5 text-2xl'>
                        <li>Sexual Re-Definition Therapy</li>
                        <li>Reparative Therapy</li>
                        <li>“Changing an alternate lifestyle or sexual preference”</li>
                        <li>“Healing sexual and relational brokenness”</li>
                        <li>“Issues of sexual brokenness”</li>
                        <li>Sexual Attraction Fluidity Exploration in Therapy (SAFE-T)</li>
                        <li>“Eliminating, reducing or decreasing frequency or intensity of unwanted Same-Sex Attraction (SSA)”</li>
                        <li>Sexual reorientation efforts</li>
                        <li>“Promoting healthy sexuality”</li>
                        <li>Addressing sexual addictions and disorders</li>
                        <li>Sexuality counseling</li>
                        <li>“Encouraging relational and sexual wholeness”</li>
                    </ol>
                    <br />
                    <p>The American Psychological Association states that harassment and abuse, as well as pressure from family, peers, and religious groups ignorant about and prejudiced against homosexuality, may cause high levels of emotional stress for LGBTQIA+ people. These pressures may lead people to feel forced into attempting to change their sexual orientation or gender identity.
                    <br />(Source: <a href='https://en.wikipedia.org/wiki/Sexual_orientation_change_efforts#Reasons_why_people_seek_to_change_sexual_orientation' target='_blank' rel='noreferrer'>Wikipedia</a>)</p>
                    <br /><p>Such practices have been rejected by every mainstream medical and mental health organization for decades, but due to continuing discrimination and societal bias against LGBTQIA+ people, some practitioners continue to conduct such practices.
                    <br /><p>(Source: <a href='https://www.hrc.org/resources/the-lies-and-dangers-of-reparative-therapy' target='_blank' rel='noreferrer'>Human Rights Campaign</a>)</p>
                    </p>
                    <div className='text-center flex my-5'>
                        <div className='my-auto font-sylexiad-bold text-2xl'>
                        <span className='italic'>“We once thought it was impossible to embrace our sexual orientation or sexual identity as an intrinsic, healthy part of who we are and who we were created to be. We know better now.”</span>
                        <br /><br />
                        — Open letter by former ex-gay leaders
                        </div>
                    </div>
                </div>

                {/* row */}
                <div className='col-span-1 font-sylexiad pt-6'>
                    <h2 className='title'>Openly LGBTQIA+ Affirming Mental Health Resources</h2>
                </div>

                <div className='col-span-1 md:col-span-2 font-sylexiad text-2xl divide-y divide-gray-300'>
                    {
                        affirmingMentalHealthResources.map(r => (
                            <div key={r.title} className='py-6'>
                                <strong className='font-sylexiad-bold text-3xl'>{r.title}</strong>
                                <ReactMarkdown children={r.content} components={CUSTOM_COMPONENT_RENDER} />
                            </div>
                        ))
                    }
                </div>

                {/* row */}
                <div className='col-span-1 font-sylexiad pt-6'>
                    <h3 className='title'>Depends on the Counsellor</h3>
                    <p className='subtitle text-xl'>{'(Individual experiences may vary with different staff & volunteers in these agencies)'}</p>
                </div>

                <div className='col-span-1 md:col-span-2 font-sylexiad text-2xl divide-y divide-gray-300'>
                    {
                        dependsOnCounsellor.map(r => (
                            <div key={r.title} className='py-6'>
                                <strong className='font-sylexiad-bold text-3xl'>{r.title}</strong>
                                <ReactMarkdown children={r.content} components={CUSTOM_COMPONENT_RENDER} />
                            </div>
                        ))
                    }
                </div>
            </div>
        </Layout>
    )
}

export default MentalHealth;